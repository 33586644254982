(function () {
	let navButton = document.querySelector('.js-menu-button');
	let navMenu = document.querySelector('.site-nav__menu');

	if (navButton == undefined || navMenu == undefined){
		return;
	}

	navButton.addEventListener('click', () => {
		navMenu.classList.toggle('active');
		navButton.classList.toggle('menu-button__lines--open');
	});
})();