(function () {

	const contactForm = document.querySelector('.contact__forms form');

	if (contactForm == undefined) {
		return;
	}

	contactForm.addEventListener('submit', event => {
		event.preventDefault();

		contactForm.querySelector('.submit-messages .submit-message-wait').classList.add('submit-message-wait--visible');

		const formData = new FormData(contactForm);
		const formID = contactForm.dataset.id;

		var formObj = {
			"sheetID": formID,
			"formFields": Array.from(formData.entries())
		};

		fetch(contactForm.getAttribute('action'), {
			method: 'POST',
			body: JSON.stringify(formObj),
			headers: {
				'Accept': 'application/json, text/plain',
				'Content-Type': 'application/json;charset=UTF-8'
			},
		}).then((res) => {
			if (res.ok) {
				window.location = "/thank-you";
			} else {
			    contactForm.querySelector('.submit-messages .submit-message-fail').classList.add('submit-message-fail--visible');
			    contactForm.querySelector('.submit-messages .submit-message-success').classList.remove('submit-message-success--visible');
			}
			
		})
		.catch((error) => {
			console.log('error: ', error);

			contactForm.querySelector('.submit-messages .submit-message-fail').classList.add('submit-message-fail--visible');
			contactForm.querySelector('.submit-messages .submit-message-success').classList.remove('submit-message-success--visible');
		});
	});
})();