(function() {

  // nodelist of accordions in DOM
  var accordions = document.querySelectorAll(".faq__accordion");

  accordions.forEach((accordion) => {
    var toggles = accordion.querySelectorAll(".faq__accordion dt");
    // check if toggles is empty
    if (!toggles.length) {
      return 0;
    }

    toggles.forEach((toggle) => {

      toggle.addEventListener('click', () => {
        if(toggle.classList.contains("active")) {
          toggle.classList.toggle("active");
        }
        else {
          // loop through all accordion dt elements to remove active class from everything
          toggles.forEach((toggle) => {
            if(toggle.classList.contains("active")) {
              toggle.classList.remove("active");
            }
          })
          // add active class to only the most recently clicked dt
          toggle.classList.toggle("active");
        }
      })
    });
  })

})();

